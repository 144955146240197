<template>
  <div class="col-12 col-lg-6 c g">
    <div class="card card-body">
        <div class="form-group">
          <h5 for="">الرابط الموحد لارساله لاولياء الامور</h5>
          <input type="text" readonly
            class="form-control form-control-lg" style="border-radius: 0px; background: #fafafa; border: solid 1px #333" v-model="link" dir="ltr" id="text-box" @click="selectme()">
        </div>
         بدلاً من ارسال رابط لاولياء الامور في كل مرة، يمكنك ارسال رابط موحد لكل العمليات ويقوم ولي الامر بكتابة رقم هوية ابنائه وتظهر شهاداتهم بشكل تلقائي. 
        <br><br>
        <div v-if="!link.includes('...')">
        <!-- AddToAny BEGIN -->
        <div class="col-12 text-right">
        <a :href="`https://www.addtoany.com/share#url=${encodeURI(link)}&amp;title=%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D8%B9%D9%84%D8%A7%D9%85%20%D8%B9%D9%86%20%D8%A7%D9%84%D8%B4%D9%87%D8%A7%D8%AF%D8%A7%D8%AA`" target="_blank"><img src="https://static.addtoany.com/buttons/a2a.svg" width="32" height="32" style="background-color:royalblue"></a>
        <a :href="`https://www.addtoany.com/add_to/whatsapp?linkurl=${encodeURI(link)}&amp;linkname=%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D8%B9%D9%84%D8%A7%D9%85%20%D8%B9%D9%86%20%D8%A7%D9%84%D8%B4%D9%87%D8%A7%D8%AF%D8%A7%D8%AA`" target="_blank"><img src="https://static.addtoany.com/buttons/whatsapp.svg" width="32" height="32" style="background-color:#9BCF53"></a>
        <a :href="`https://www.addtoany.com/add_to/telegram?linkurl=${encodeURI(link)}&amp;linkname=%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D8%B9%D9%84%D8%A7%D9%85%20%D8%B9%D9%86%20%D8%A7%D9%84%D8%B4%D9%87%D8%A7%D8%AF%D8%A7%D8%AA`" target="_blank"><img src="https://static.addtoany.com/buttons/telegram.svg" width="32" height="32" style="background-color:#40A2D8"></a>
        <a :href="`https://www.addtoany.com/add_to/facebook?linkurl=${encodeURI(link)}&amp;linkname=%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D8%B9%D9%84%D8%A7%D9%85%20%D8%B9%D9%86%20%D8%A7%D9%84%D8%B4%D9%87%D8%A7%D8%AF%D8%A7%D8%AA`" target="_blank"><img src="https://static.addtoany.com/buttons/facebook.svg" width="32" height="32" style="background-color:#40A2E3"></a>
        <a :href="`https://www.addtoany.com/add_to/x?linkurl=${encodeURI(link)}&amp;linkname=%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D8%B9%D9%84%D8%A7%D9%85%20%D8%B9%D9%86%20%D8%A7%D9%84%D8%B4%D9%87%D8%A7%D8%AF%D8%A7%D8%AA`" target="_blank"><img src="https://static.addtoany.com/buttons/x.svg" width="32" height="32" style="background-color:#000000"></a>
        <a :href="`https://www.addtoany.com/add_to/twitter?linkurl=${encodeURI(link)}&amp;linkname=%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D8%B9%D9%84%D8%A7%D9%85%20%D8%B9%D9%86%20%D8%A7%D9%84%D8%B4%D9%87%D8%A7%D8%AF%D8%A7%D8%AA`" target="_blank"><img src="https://static.addtoany.com/buttons/twitter.svg" width="32" height="32" style="background-color:#0B60B0"></a>
        </div>
        <!-- AddToAny END -->
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            link: "جاري التحميل...",
            user: JSON.parse(localStorage.getItem('user')),
        }
    },
    created(){
        if(!checkPer("results")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        $.post(api + '/user/results/link', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loaded = true;
            if(r.status != 100){
                alert(r.response, 200)
            }else{
                g.link = `${r.response}`
            }
        }).fail(function(){
            alert("حدث خطأ", 200)
        })
    },
    methods: {
        selectme(){
            const input = document.getElementById("text-box");
            input.focus();
            input.select();
        }
    }
}
</script>

<style>

</style>